export default [
  {
    title: 'Inicio',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'home',
    action: 'read',
  },
  {
    title: 'Modulos',
    route: 'modules',
    icon: 'CreditCardIcon',
    resource: 'home',
    action: 'read',
  },
  {
    title: 'Gestor Turnos',
    route: 'gestor-turnos',
    icon: 'AirplayIcon',
    resource: 'home',
    action: 'read',
  },
  {
    title: 'Configuracion',
    route: 'config',
    icon: 'SettingsIcon',
    resource: 'home',
    action: 'read',
  },
]
